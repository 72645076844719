import React from "react"
import Layout from "../components/Layout"
import "../pages/css/index.css"

const Home = () => {
  return (
    <Layout>
      <div className="home_page_container">
        <h2>
          Coding<span style={{ color: "#9962ffcd" }}>G</span>yan
        </h2>
      </div>
    </Layout>
  )
}

export default Home
